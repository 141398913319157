import { Fragment, useMemo } from 'react'

export const MultipleFilter = (rows: any, accessor: any, filterValue: any) => {
  const arr: any[] = []
  rows.forEach((val: any) => {
    if (accessor.includes('topics')) {
      val.values[accessor].forEach((i: any) => {
        if (filterValue.includes(i)) arr.push(val)
      })
    } else {
      if (filterValue.includes(val.values[accessor])) arr.push(val)
    }
  })
  return arr
}

// For filtering columns containing either of the listed values ('OR' logic)
export const SingleSelectFilter = (props: any) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id },
  } = props

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((x: any) => {
      if (!x.values[id]) {
        return
      }
      if (id === 'topics') {
        x.values[id].forEach((i: string) => {
          if (!options.has(i)) {
            options.add(i)
          }
        })
      } else {
        options.add(x.values[id])
      }
    })
    return [...options.values()].sort()
  }, [id, preFilteredRows])

  const setFilteredParams = (filterArr: any, val: any) => {
    if (filterArr.includes(val)) {
      filterArr = filterArr.filter((n: any) => {
        return n !== val
      })
    } else {
      filterArr.push(val)
    }
    if (filterArr.length === 0) {
      filterArr = undefined
    }
    return filterArr
  }

  const resetFilter = (options: any) => {
    setFilter(undefined)
    options.forEach((optId: string) => {
      const checkbox = document.getElementById(`${id}-${optId}`) as HTMLInputElement | null

      if (checkbox != null) {
        checkbox.checked = checkbox.checked ? false : checkbox.checked
      }
    })
  }

  return (
    <Fragment>
      <div id={id + '-filter'} className="row-filter-container" style={{ display: 'none' }}>
        <div className="block checkbox-filter-container">
          <div className="checkbox-container">
            {options.map((option: any, i: any) => {
              return (
                <Fragment key={`${id}-${option}-${i}`}>
                  <div className="flex items-center checkbox-options">
                    <input
                      type="checkbox"
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      id={`${id}-${option}`}
                      name={option}
                      value={option}
                      onChange={e => {
                        setFilter(setFilteredParams(filterValue, e.target.value))
                      }}
                    ></input>
                    <label htmlFor={option} className="ml-1.5 font-medium text-gray-700">
                      {option}
                    </label>
                  </div>
                </Fragment>
              )
            })}
          </div>
          <button
            className="filter-footer"
            onClick={e => {
              resetFilter(options)
            }}
          >
            X Clear
          </button>
        </div>
      </div>
    </Fragment>
  )
}

// For filtering columns containing atleast one of the listed values ('AND' logic)
export const MultipleSelectFilter = (props: any) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id },
  } = props
  const options = useMemo(() => {
    let counts: any[] = []
    let langs: string[] = []
    preFilteredRows.forEach((x: any) => {
      if (!x.values[id]) {
        return
      }
      x.values[id].forEach((i: string) => {
        if (!langs.includes(i)) {
          langs.push(i)
        }
      })
      x = x.values[id].toString()

      counts[x] = (counts[x] || 0) + 1
    })
    return langs
  }, [id, preFilteredRows])

  const setFilteredParams = (filterArr: any, val: any) => {
    if (filterArr.includes(val)) {
      filterArr = filterArr.filter((n: any) => {
        return n !== val
      })
    } else {
      filterArr.push(val)
    }

    if (filterArr.length === 0) filterArr = undefined
    return filterArr
  }

  const resetFilter = (options: any) => {
    setFilter(undefined)
    options.forEach((optId: string) => {
      const checkbox = document.getElementById(`${id}-${optId}`) as HTMLInputElement | null

      if (checkbox != null) {
        checkbox.checked = checkbox.checked ? false : checkbox.checked
      }
    })
  }

  return (
    <Fragment>
      <div id={id + '-filter'} className="row-filter-container" style={{ display: 'none' }}>
        <div className="block checkbox-filter-container">
          <div className="checkbox-container">
            {options.map((option, i) => {
              return (
                <Fragment key={`${id}-${option}-${i}`}>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      id={`${id}-${option}`}
                      name={option}
                      value={option}
                      onChange={e => {
                        setFilter(setFilteredParams(filterValue, e.target.value))
                      }}
                    ></input>
                    <label htmlFor={option} className="ml-1.5 font-medium text-gray-700">
                      {option}
                    </label>
                  </div>
                </Fragment>
              )
            })}
          </div>
          <button
            className="filter-footer"
            onClick={e => {
              resetFilter(options)
            }}
          >
            X Clear
          </button>
        </div>
      </div>
    </Fragment>
  )
}
