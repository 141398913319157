import React from 'react'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { CourseRow } from '../../types'
import { RenderCourseDesc, getVersionItem, setVersionItems, setDeployedVersions } from './course-row-info'
import { SingleSelectFilter, MultipleFilter, MultipleSelectFilter } from './row-checkbox-filter'

export const columns = [
  {
    Header: 'Project',
    accessor: 'bugs_url',
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return (
        <Link href={row.bugs_url} target="_blank">
          {row.project || ''}
        </Link>
      )
    },
  },
  {
    Header: 'Course Name',
    accessor: 'description',
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return RenderCourseDesc(row, props.column.versions, props.column.branch)
    },
  },
  {
    Header: 'Github Key',
    accessor: 'name',
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return (
        <Link href={row.html_url} rel="noopener noreferrer" target="_blank">
          {row.name}
        </Link>
      )
    },
  },
  {
    Header: 'Labels',
    accessor: 'topics',
    Filter: SingleSelectFilter,
    filter: MultipleFilter,
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return (
        <List dense={true}>
          {row.topics?.map((item, i) => {
            return (
              <React.Fragment key={`${item}-${i}`}>
                <Chip size="small" label={item} />
                <>&nbsp;</>
              </React.Fragment>
            )
          })}
        </List>
      )
    },
  },
  {
    Header: 'Theme',
    accessor: 'course_settings.theme',
    Filter: SingleSelectFilter,
    filter: MultipleFilter,
    id: 'theme',
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return (
        <Box textAlign="left">
          <Typography>{row.theme}</Typography>
        </Box>
      )
    },
  },
  {
    Header: 'Furnace Version',
    accessor: 'developVersions.componentsVersion',
    id: 'furnace',
    Filter: SingleSelectFilter,
    filter: MultipleFilter,
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      const developVersion = row.developBranch.componentsVersion
      const developItem = getVersionItem(props, developVersion, 'develop')
      const masterItem = getVersionItem(props, row.masterBranch.componentsVersion, 'master')

      return (
        <List dense={true}>
          {developItem}
          {masterItem}
        </List>
      )
    },
  },
  {
    Header: 'COM Version',
    accessor: 'developVersions.componentsVersion',
    id: 'legacy-components',
    Filter: SingleSelectFilter,
    filter: MultipleFilter,
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return setVersionItems(props, row.developBranch.componentsVersion, row.masterBranch.componentsVersion)
    },
  },
  {
    Header: 'SDK Version',
    accessor: 'developVersions.sdkVersion',
    id: 'legacy-sdk',
    Filter: SingleSelectFilter,
    filter: MultipleFilter,
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return setVersionItems(props, row.developBranch.sdkVersion, row.masterBranch.sdkVersion)
    },
  },
  // Leaving this out for now as filtering for version columns have changed. Maybe this is no longer needed?
  // {
  //   Header: 'Legacy build version',
  //   accessor: 'developVersions.buildVersion',
  //   id: 'legacy-build',
  //   // Filter: SingleSelectFilter,
  //   // filter: MultipleFilter,
  //   Cell: (props: any) => {
  //     const row = props.row.original as CourseRow
  //     return setVersionItems(props, row.developVersions.componentsVersion, row.masterVersions.componentsVersion)
  //   },
  // },
  {
    Header: 'Deployment',
    accessor: 'csConfigVersions.sandbox',
    id: 'foundry-deployment',
    Filter: SingleSelectFilter,
    filter: MultipleFilter,
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return setDeployedVersions(row)
    },
  },
  {
    Header: 'Languages',
    accessor: 'course_settings.supported_languages',
    Filter: MultipleSelectFilter,
    id: 'languages',
    Cell: (props: any) => {
      const row = props.row.original as CourseRow
      return (
        <Typography>
          {row.supported_languages
            .map(item => {
              return item
            })
            .join(', ')}
        </Typography>
      )
    },
  },
]
