import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import Chip from '@material-ui/core/Chip'
import { CourseRow } from '../../types'

const renderOpenReleases = function (name: string, description: string, item: any) {
  const version = item.name.replace(/release\/|hotfix\//gi, '').replace(/\./g, '_')
  const url = `http://everfi-curriculums.s3.amazonaws.com/curriculums/${name}/${version}/index.html#`
  return (
    <ListItem key={item}>
      <Link href={url} target="_blank">
        {description} ({item.name})
      </Link>
    </ListItem>
  )
}
export const RenderCourseDesc = function (row: CourseRow, branches: string[], selection: string) {
  const description = row.description ? row.description : row.name
  let version = row.default_branch
  let name = row.name.toLowerCase()
  let developItem, releaseItems, masterItem

  if (branches.includes('develop') || selection === 'all') {
    developItem = (
      <ListItem key="develop">
        <Link href={row.html_url} target="_blank">
          {description} ({version})
        </Link>
      </ListItem>
    )
  }
  if (branches.includes('master') || selection === 'all') {
    const version = row.masterBranch?.version
    const s3Version = version.replace(/\./g, '_')
    masterItem = (
      <ListItem key="master">
        <Link
          href={`http://s3.amazonaws.com/everfi-curriculums/curriculums/${name}/${s3Version}/index.html#`}
          target="_blank"
        >
          {description} ({row.masterBranch.version})
        </Link>
      </ListItem>
    )
  }
  if (branches.includes('release-hotfix') || selection === 'all') {
    releaseItems =
      row.openReleases.length === 0
        ? ''
        : row.openReleases.map((item: any) => {
            return renderOpenReleases(name, description, item)
          })
  }
  return (
    <List dense={true}>
      {developItem ? developItem : ''}
      {releaseItems ? releaseItems : ''}
      {masterItem ? masterItem : ''}
    </List>
  )
}

export const getVersionItem = function (props: any, version: string, branch: string) {
  if (!props.column.versions.includes(branch) && !props.column.versions.includes('all')) {
    return ''
  }
  const branchString = branch === 'develop' ? branch + ': ' : ''
  return (
    <React.Fragment>
      <Chip size="small" label={branchString + version} color="primary" />
      <>&nbsp;</>
    </React.Fragment>
  )
}
export const setVersionItems = function (props: CourseRow, developVersion: string, masterVersion: string) {
  const developItem = getVersionItem(props, developVersion, 'develop')
  const masterItem = getVersionItem(props, masterVersion, 'master')
  return (
    <List dense={true}>
      {developItem}
      {masterItem}
    </List>
  )
}

export const setDeployedVersions = function (row: CourseRow) {
  const sandbox = row.csConfigVersions?.sandbox
  const staging = row.csConfigVersions?.staging
  let stagingItem, sandboxItem
  const stagingChip = compareVersions(staging, row.masterBranch.version, 'Staging', row.openReleases)
  const sandboxChip = compareVersions(sandbox, row.masterBranch.version, 'Sandbox', row.openReleases)

  stagingItem = (
    <React.Fragment>
      {stagingChip}
      <>&nbsp;</>
    </React.Fragment>
  )
  sandboxItem = (
    <React.Fragment>
      {sandboxChip}
      <>&nbsp;</>
    </React.Fragment>
  )

  return (
    <List>
      {stagingItem}
      {sandboxItem}
    </List>
  )
}

const compareVersions = function (deployedVer: any, courseVer: any, name: string, releases: any) {
  // if there's not version to compare, don't render anything
  if (!deployedVer) {
    return ''
  }

  const label = `${name}: ${deployedVer}`
  const errorChip = <Chip size="small" label={label} style={{ background: 'red', color: 'white' }} />

  // if version does not match master
  if (name === 'Staging' && deployedVer !== courseVer) {
    return errorChip
  }
  // if there's no release
  if (name === 'Sandbox' && deployedVer !== 'develop' && releases.length === 0) {
    return errorChip
  }
  // if there's a release
  if (name === 'Sandbox' && deployedVer === 'develop' && releases.length > 0) {
    return errorChip
  }
  return <Chip size="small" label={label} color="primary" />
}
