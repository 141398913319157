import { FC, useContext } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import GitHubIcon from '@material-ui/icons/GitHub'
import Avatar from '@material-ui/core/Avatar'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import { DataContext } from '../context/data-context'

const useStyles = makeStyles(() => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarTitle: {
    display: 'block',
    flexGrow: 1,
    margin: 10,
  },
}))

export const Header: FC = () => {
  const classes = useStyles()
  const dataContext = useContext(DataContext)
  /**
   * DOM
   */
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Avatar alt="EverFi" variant="square" src={`https://avatars.githubusercontent.com/u/686693?v=4`} />
        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          EverFi
        </Typography>
        <Box textAlign="right" p={'1rem'}>
          <Typography variant="button">Total: {dataContext?.data.repos.length} rows</Typography>
          &nbsp; | &nbsp;
          <Typography variant="button">
            Last Updated:{' '}
            {dataContext?.data.lastUpdated
              ? new Date(dataContext?.data.lastUpdated).toLocaleString()
              : new Date().toLocaleString()}
          </Typography>
        </Box>
        |
        <nav className={classes.nav}>
          <Typography color="inherit" variant="caption" noWrap className={classes.toolbarTitle}>
            Course Version Reporter
          </Typography>
          <Link
            href={`https://github.com/EverFi/CourseVersionReporter/`}
            rel="noopener noreferrer"
            target="_blank"
            color="secondary"
          >
            <GitHubIcon />
          </Link>
        </nav>
      </Toolbar>
    </AppBar>
  )
}
