import { FC } from 'react'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'

export const ColumnTextFilter: FC = (props: any) => {
  const {
    column: { filterValue, setFilter, id },
  } = props
  return (
    <div id={id + '-filter'} className="row-filter-container" style={{ display: 'none' }}>
      <div className="block textbox-filter">
        <TextField
          label=""
          size="small"
          value={filterValue || ''}
          onChange={e => {
            setFilter(e.target.value || undefined)
          }}
          placeholder={`Search`}
        />
        <SearchIcon />
      </div>
    </div>
  )
}
