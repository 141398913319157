import { FC, useMemo, useState } from 'react'
import { useTable, useSortBy, useFilters } from 'react-table'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Box from '@material-ui/core/Box'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import { ColumnTextFilter } from './column-text-filter'
import { TableFilters } from './table-filters'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    flex: 1,
    maxHeight: 'calc(100vh - 200px)',
  },
  table: {
    minWidth: 650,
  },
})

interface Props {
  columns: any
  data: any
}

const compareIgnoreCase = function(a: string, b: string) {
  let r1 = a?.toString().toLowerCase()
  let r2 = b?.toString().toLowerCase()
  if (r1 < r2) {
    return -1
  }
  if (r1 > r2) {
    return 1
  }
  return 0
}

export const CourseGrid: FC<Props> = ({ columns, data }) => {
  const classes = useStyles()

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ColumnTextFilter,
    }),
    []
  )

  const { getTableProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
        // set up initial hidden columns
        hiddenColumns: ['legacy-components', 'legacy-sdk'],
      },
      sortTypes: {
        alphanumeric: (row1: any, row2: any, columnName: string) => {
          return compareIgnoreCase(row1.values[columnName], row2.values[columnName])
        },
      },
    },
    useFilters,
    useSortBy
  )

  const [filteredBranch, filterBranch] = useState<string>('')
  const [filteredVersions, updateVersions] = useState(['all']) // Course version fitlering
  const [courseTypes, updateColumnDisplay] = useState(['legacy-components', 'legacy-sdk']) // Course version column visibility
  const [display, displayCheckboxFilter] = useState(false) // Checkbox filter visibility

  const setHeaderIcon = (id: string, header: string) => {
    let icon
    const freeTextSearchColumns = ['Project', 'Course Name', 'Github Key']
    if (freeTextSearchColumns.includes(header)) {
      icon = <SearchIcon className="header-icons" />
    } else {
      icon = <FilterListIcon className="header-icons" />
    }
    return (
      <span
        id={id + '-filter-btn'}
        onClick={() => {
          renderColumnFilter(id)
        }}
      >
        {icon}
      </span>
    )
  }

  const renderColumnFilter = (id: string) => {
    var elem = document.getElementById(id + '-filter')
    if (!elem) {
      return
    }
    elem.style.display = display ? 'none' : 'block'
    displayCheckboxFilter(!display)
  }

  /**
   * DOM
   */
  return (
    <Paper className={classes.root}>
      <TableFilters
        setHiddenColumns={setHiddenColumns}
        filterBranch={filterBranch}
        updateVersions={updateVersions}
        courseTypes={courseTypes}
        updateColumnDisplay={updateColumnDisplay}
      />
      <TableContainer className={classes.container}>
        <Table {...getTableProps()} stickyHeader>
          <TableHead>
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell variant={'head'} {...column.getHeaderProps()}>
                    {setHeaderIcon(column.id, column.Header)}
                    &nbsp;
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                      {...column.getHeaderProps([column.getSortByToggleProps()])}
                      className="table-headers"
                    >
                      <Typography>{column.render('Header')}</Typography>
                    </TableSortLabel>
                    <Box>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    cell.column.branch = !filteredBranch ? 'all' : filteredBranch
                    cell.column.versions = filteredVersions
                    return (
                      <TableCell {...cell.getCellProps()} style={{ width: 100 }}>
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
