import 'fontsource-roboto'
import { FC, useContext } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Header } from './components/header'
import { DataContext } from './context/data-context'
import { CourseGrid } from './components/course-grid'
import { columns } from './components/course-grid/columns'

export const App: FC = () => {
  /**
   * Context
   */
  const dataContext = useContext(DataContext)

  /**
   * DOM
   */
  return (
    <>
      <CssBaseline />
      <Header />
      <CourseGrid columns={columns} data={dataContext?.data.repos} />
    </>
  )
}
