import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react'
import dbJson from '../db/data.json'
import { CourseRow } from '../types'

export interface DataContextProps {
  data: {
    isBusy: boolean
    repos: CourseRow[]
    lastUpdated: string
  }
}

export const DataContext = createContext<DataContextProps | null>(null)

type DataContextProviderProps = DataContextProps & PropsWithChildren<any>

const DataContextProvider: FC<DataContextProviderProps> = ({ children }) => {
  /**
   * State
   */
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [repos, setRepos] = useState<any[]>([])
  const [lastUpdated, setLastUpdated] = useState<string>('')

  /**
   * Hooks
   */
  useEffect(() => {
    if (!dbJson || !dbJson.repos || !dbJson.lastUpdated) {
      return
    }
    setIsBusy(true)
    setRepos(dbJson.repos)
    setLastUpdated((dbJson.lastUpdated as unknown) as string)
    setIsBusy(false)
  }, [])

  /**
   * DOM
   */
  const value: DataContextProps = {
    data: {
      isBusy,
      repos,
      lastUpdated,
    },
  }
  return (
    <DataContext.Provider value={value}>
      <section>{children}</section>
    </DataContext.Provider>
  )
}

export default DataContextProvider
