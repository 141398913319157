import { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import './course-grid.scss'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'

interface TableFilterProps {
  setHiddenColumns: any
  filterBranch: any
  updateVersions: any
  updateColumnDisplay: any
  courseTypes: any
}

export const TableFilters: FC<TableFilterProps> = ({
  setHiddenColumns,
  filterBranch,
  updateVersions,
  updateColumnDisplay,
  courseTypes,
}) => {
  const list = ['furnace', 'legacy-components', 'legacy-sdk']
  const filterTopics = function(element: any) {
    if (element.id !== '') {
      element?.classList.toggle('active')
    }

    if (element.children[0]) {
      element.children[0].style.display = element.classList.contains('active') ? 'block' : 'none'
    }

    updateColumnVisibility(element.id)

    if (element.id === '') {
      resetActive(list, 'furnace')
      courseTypes = ['legacy-components', 'legacy-sdk']
      setHiddenColumns(courseTypes)
      updateColumnDisplay(courseTypes)
    }
  }

  const updateColumnVisibility = function(id: string) {
    updateColumnDisplay((arr: any[]) => {
      if (arr.includes(id)) {
        const index = arr.indexOf(id)
        if (index > -1) {
          arr = arr.filter((d, i) => i !== index)
        }
      } else {
        arr.push(id)
      }

      setHiddenColumns(arr)

      return [...arr]
    })
  }

  const UpdateBranches = function(element: any) {
    const id = element.id
    filterBranch((id as unknown) as string)
    const ids = ['all', 'develop', 'master', 'release-hotfix']

    updateVersions((arr: any[]) => {
      if (id !== 'all') {
        arr = arr.filter((d, i) => i !== arr.indexOf('all'))
      }
      if (arr.includes(id)) {
        const index = arr.indexOf(id)
        if (index > -1) {
          arr = arr.filter((d, i) => i !== index)
        }
      } else {
        arr.push(id)
      }
      if (arr.length === 0) {
        resetActive(ids, 'all')
      }

      return [...arr]
    })

    setActive(element, ids)
  }

  const setActive = (element: any, ids: string[]) => {
    if (!element) {
      return
    }

    const id = element.id

    if (id !== 'all') {
      if (id !== '') {
        element.classList.toggle('active')
      }
      document.getElementById('all')?.classList.remove('active')
      ;((document.getElementById('all')?.children[0] as unknown) as HTMLElement).style.display = 'none'
    }

    if (id === 'all') {
      resetActive(ids, 'all')
      document.getElementById('all')?.classList.add('active')
    }

    if (id === '') {
      resetActive(ids, 'all')
    }

    if (element.children[0]) {
      element.children[0].style.display = element.classList.contains('active') ? 'block' : 'none'
    }
  }

  const resetActive = function(ids: string[], id: string) {
    ids.forEach(idName => {
      var elem = document.getElementById(idName)
      if (!elem?.classList.contains('active')) {
        return
      }
      elem.classList.remove('active')
      if (elem.children[0]) {
        ;((elem.children[0] as unknown) as HTMLElement).style.display = 'none'
      }
    })

    const idName = document.getElementById(id)
    idName?.classList.add('active')

    if (idName?.children[0]) {
      ;((idName.children[0] as unknown) as HTMLElement).style.display = 'block'
    }

    updateVersions((arr: any[]) => {
      arr = [id]
      return [...arr]
    })
  }

  return (
    <Box className="table-filter-container">
      <Box className="table-filters">
        <Typography className="filter-button-text">COURSE TYPE:</Typography>
        <button
          className={'buttons filter-button-text active'}
          onClick={e => filterTopics(e.currentTarget)}
          id="furnace"
        >
          Furnace
          <CheckOutlinedIcon style={{ fontSize: 15 }} />
        </button>
        <button
          className={'buttons filter-button-text'}
          onClick={e => filterTopics(e.currentTarget)}
          id="legacy-components"
        >
          Legacy Components
          <CheckOutlinedIcon style={{ fontSize: 15, display: 'none' }} />
        </button>
        <button className={'buttons filter-button-text'} onClick={e => filterTopics(e.currentTarget)} id="legacy-sdk">
          Legacy SDK
          <CheckOutlinedIcon style={{ fontSize: 15, display: 'none' }} />
        </button>
        <button className={'buttons filter-button-text'} onClick={e => filterTopics(e.currentTarget)} id="">
          Reset filter
        </button>
      </Box>

      <Box className="table-filters">
        <Typography className="filter-button-text">COURSE VERSION:</Typography>
        <button className={'buttons filter-button-text active'} onClick={e => UpdateBranches(e.currentTarget)} id="all">
          All
          <CheckOutlinedIcon style={{ fontSize: 15 }} />
        </button>
        <button className={'buttons filter-button-text'} onClick={e => UpdateBranches(e.currentTarget)} id="develop">
          Develop
          <CheckOutlinedIcon style={{ fontSize: 15, display: 'none' }} />
        </button>
        <Tooltip
          title="This report does not reflect information from Foundry or Homeroom. Confirm course configurations in those platform environments separately."
          arrow
        >
          <button className={'buttons filter-button-text'} onClick={e => UpdateBranches(e.currentTarget)} id="master">
            Latest
            <CheckOutlinedIcon style={{ fontSize: 15, display: 'none' }} />
          </button>
        </Tooltip>
        <button
          className={'buttons filter-button-text'}
          onClick={e => UpdateBranches(e.currentTarget)}
          id="release-hotfix"
        >
          Open releases/hotfixes
          <CheckOutlinedIcon style={{ fontSize: 15, display: 'none' }} />
        </button>
        <button className={'buttons filter-button-text'} onClick={e => UpdateBranches(e.currentTarget)} id="">
          Reset filter
        </button>
      </Box>

      <Box className="table-filters">
        <Typography className="filter-button-text">RESET ALL:</Typography>
        <button
          className={'buttons filter-button-text'}
          onClick={e => {
            filterTopics(e.currentTarget)
            UpdateBranches(e.currentTarget)
          }}
          id=""
        >
          Reset filter
        </button>
      </Box>
    </Box>
  )
}
